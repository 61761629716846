html, body {
  overflow: hidden;
  height: 100%;
}

.App {
  text-align: center;
  padding: 30px;
  padding-top: 40px;
  background-color: #282c34;
}

.FrontPagePicture {
  height: 40vmin;
  width: 40vmin;
  pointer-events: none;
  border-radius: 50px;
  border: solid rgba(0, 0, 0, 0.331) 5px;
  margin: 10px;
  margin-top: 40px;
  box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
}

.App-header {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.PicAndResume {
  margin-bottom: 20px;
  display: flex;
}

.NameAndResume {
  margin-right: 50px;
}

p {
  font-size: calc(30px + 2vmin);
  font-weight: bold;
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 60px;
}


button {
  cursor: pointer;
  border-radius: 10px;
  border: none;
  padding: 14px;
  margin: 0;
  text-align: center;
  font-weight: 800;
  color: black;
  text-decoration: none;
  transition: transform .2s;
}

.button {
  box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 10px 6px 38px 5px rgba(0, 0, 0, 0.32);
}

.button:hover {
  background-color: #d53d35;
  transition: background-color 200ms linear;
  color: white;
}

.ProjectSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.Project {
  width: 100%;
}

.Abilities {
  margin-bottom: 0px;
}

.ability {
  margin-right: 10px;
}

.contact {
  color: rgba(255, 255, 255, 0.52);
  font-size: medium;
  margin-bottom: 0;
}

@media only screen and (max-width: 850px) {
  .ProjectSection {
    display: flex;
    flex-direction: column;
  }

  .PicAndResume {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .NameAndResume {
    margin-bottom: 30px;
    margin-right: 0px;
  }

  .FrontPagePicture {
    height: 30vmin;
    width: 30vmin;
    pointer-events: none;
    border-radius: 50px;
    border: solid rgba(0, 0, 0, 0.331) 5px;
    margin: 10px;
  }
}