.projectBox {
    cursor: default;
    width: 90%;
    height: 250px;
    padding: 20px;
    margin: 4px;
}

.projectBox:hover {
    background-color: whitesmoke;
    box-shadow: 10px 6px 38px 5px rgba(0,0,0,0.32);
-webkit-box-shadow: 10px 6px 38px 5px rgba(0,0,0,0.32);
-moz-box-shadow: 10px 6px 38px 5px rgba(0,0,0,0.32);
} 

.projectBox:hover .gitLogo,
.projectBox:hover .webLogo {
    transform: rotate(1turn) scale(1.2);
}

.projectBox:hover .gitLogo:hover,
.projectBox:hover .webLogo:hover {
    cursor: pointer;
    transform:scale(2);
}

.logoLinks {
    margin: 10px;
}

.gitLogo {
    transition: transform .2s;
    margin-right: 20%;
}

.webLogo {
    transition: transform .2s;  
}